<template>
  <Dialog
    header="Konfirmasi"
    v-model:visible="showDenyModal"
    :modal="true"
    position="center"
  >
    <div class="flex mb-4 flex-column">
      <div class="mb-2">Alasan</div>
      <Textarea v-model="reason" rows="5" cols="70" :disabled="loading" />
    </div>
    <template #footer>
      <button
        class="p-button p-component p-button-danger tw-px-10"
        disabled
        v-if="loading"
      >
        <ProgressSpinner
          stroke-width="5"
          animation-duration="2s"
          style="width: 30px; height: 30px"
        />
      </button>
      <Button
        label="Confirm"
        class="p-button-danger tw-h-10"
        @click="denySalon"
        v-else
      />
    </template>
  </Dialog>

  <Dialog
    header="Konfirmasi"
    v-model:visible="showApproveModal"
    :style="{ width: '350px' }"
    :modal="true"
  >
    <div class="flex align-items-center justify-content-center">
      <i class="mr-3 pi pi-exclamation-triangle" style="font-size: 2rem" />
      <span>Apakah Anda yakin ingin melanjutkan?</span>
    </div>
    <template #footer>
      <Button
        label="Tidak"
        icon="pi pi-times"
        @click="showApproveModal = false"
        class="p-button-text"
      />
      <button class="p-button p-component tw-px-10" disabled v-if="loading">
        <ProgressSpinner
          stroke-width="5"
          animation-duration="2s"
          style="width: 30px; height: 30px"
        />
      </button>
      <Button
        label="Ya"
        icon="pi pi-check"
        @click="approveSalon"
        class="p-button"
        autofocus
        v-else
      />
    </template>
  </Dialog>

  <div style="margin: -0px" class="tw-bg-white tw-relative">
    <router-link
      :to="{ name: 'salons' }"
      class="block mb-3 text-lg tw-font-semibold tw-absolute tw-top-5 tw-left-5 tw-bg-white tw-px-4 tw-py-2 tw-rounded tw-shadow"
      v-if="!isAdminSalon()"
    >
      <i class="mr-2 pi pi-arrow-left"></i>
      Kembali
    </router-link>

    <div class="tw-w-full tw-mb-5">
      <img
        style="height: 300px; object-fit: cover"
        :src="salon.image ? salon.image : require('@/assets/images/dummy.jpg')"
        class="tw-w-full"
      />
    </div>

    <div class="grid">
      <div class="col-12 lg:col-6 tw-py-5 tw-px-8">
        <section class="tw-flex-1 tw-flex tw-flex-col tw-gap-3">
          <div class="tw-flex tw-justify-between">
            <div
              :class="`tw-px-4 tw-py-1 tw-h-10 tw-border-2 ${
                salon.status_name === 'Active' ||
                salon.status_name === 'Approve'
                  ? 'tw-bg-green-100 tw-border-green-200 tw-text-green-600'
                  : 'tw-bg-red-100 tw-border-red-200 tw-text-red-600'
              } inter-semibold tw-rounded-full`"
            >
              {{ salon.status_name }}
            </div>
            <div class="tw-flex">
              <template v-if="salon.status === 0 && !isAdminSalon()">
                <Button
                  icon="pi pi-check"
                  class="tw-py-2 tw-px-4 tw-border-gray-200 tw-bg-white tw-text-green-500 tw-shadow-md tw-h-10 hover:tw-bg-gray-300 tw-mr-3"
                  label="Approve"
                  @click="showApproveModal = true"
                />
                <Button
                  icon="pi pi-times"
                  class="tw-py-2 tw-px-4 tw-border-gray-200 tw-bg-white tw-text-red-500 tw-shadow-md tw-h-10 hover:tw-bg-gray-300 tw-mr-3"
                  label="Deny"
                  @click="denySalon"
                />
              </template>
              <template
                v-if="
                  isAdminPriti() && salon.status !== 2 && salon.status !== 0
                "
              >
                <SelectButton
                  v-model="activeValue"
                  :options="['Aktif', 'Tidak Aktif']"
                  aria-labelledby="basic"
                  class="tw-mr-3 tw-shadow-md tw-border-gray-200"
                  @change="
                    activeInactiveSalon(salon.id, activeValue == 'Aktif')
                  "
                />
              </template>
              <Button
                icon="pi pi-pencil"
                class="tw-py-2 tw-px-4 tw-border-gray-200 tw-bg-white tw-text-black tw-shadow-md tw-h-10 hover:tw-bg-gray-300"
                label="Edit Salon"
                @click="
                  router.push({
                    name: isAdminSalon() ? 'edit-salon-admin' : 'edit-salon',
                    params: isAdminSalon() ? null : { id: route.params.id },
                  })
                "
              />
            </div>
          </div>
          <h1 class="tw-text-3xl tw-font-semibold md:tw-text-4xl">
            {{ salon.name }}
          </h1>
          <p class="tw-opacity-80 tw-mb-4">
            {{ salon.description }}
          </p>

          <aside class="tw-bg-gray-100 tw-rounded-xl tw-px-5 tw-py-5 tw-mb-5">
            <div class="tw-border-b-2 tw-border-b-gray-300 tw-pb-4">
              <p class="tw-opacity-60 tw-mb-2 tw-text-sm">Alamat</p>
              <p class="inter-medium tw-text-sm">{{ salon.address }}</p>
            </div>
            <div class="tw-border-b-2 tw-border-b-gray-300 tw-py-4">
              <p class="tw-opacity-60 tw-mb-2 tw-text-sm">Provinsi</p>
              <p class="inter-medium tw-text-sm">{{ salon.provinces_name }}</p>
            </div>
            <div class="tw-border-b-2 tw-border-b-gray-300 tw-py-4">
              <p class="tw-opacity-60 tw-mb-2 tw-text-sm">Kota/Kabupaten</p>
              <p class="inter-medium tw-text-sm">{{ salon.cities_name }}</p>
            </div>
            <div class="tw-pt-4 tw-pb-1">
              <p class="tw-opacity-60 tw-mb-2 tw-text-sm">Kecamatan</p>
              <p class="inter-medium tw-text-sm">{{ salon.districts_name }}</p>
            </div>
          </aside>

          <aside>
            <h2
              class="tw-text-xl tw-font-semibold tw-flex tw-items-center tw-gap-1"
            >
              <i class="pi pi-calendar tw-text-blue-600 tw-text-2xl"></i>
              Jam Operasional
            </h2>
            <ul class="tw-grid tw-grid-cols-3">
              <li class="tw-p-3 tw-border-b-2 tw-border-b-gray-100">
                <p class="tw-opacity-60 tw-mb-2">Senin</p>
                <p
                  class="inter-semibold"
                  :class="
                    schedules.monday_open
                      ? 'tw-text-black'
                      : 'tw-text-red-600 inter-bold'
                  "
                >
                  {{
                    schedules.monday_open
                      ? `${schedules.monday_open_time} - ${schedules.monday_close_time}`
                      : "Tutup"
                  }}
                </p>
              </li>
              <li class="tw-p-3 tw-border-b-2 tw-border-b-gray-100">
                <p class="tw-opacity-60 tw-mb-2">Selasa</p>
                <p
                  class="inter-semibold"
                  :class="
                    schedules.tuesday_open
                      ? 'tw-text-black'
                      : 'tw-text-red-600 inter-bold'
                  "
                >
                  {{
                    schedules.tuesday_open
                      ? `${schedules.tuesday_open_time} - ${schedules.tuesday_close_time}`
                      : "Tutup"
                  }}
                </p>
              </li>
              <li class="tw-p-3 tw-border-b-2 tw-border-b-gray-100">
                <p class="tw-opacity-60 tw-mb-2">Rabu</p>
                <p
                  class="inter-semibold"
                  :class="
                    schedules.wednesday_open
                      ? 'tw-text-black'
                      : 'tw-text-red-600 inter-bold'
                  "
                >
                  {{
                    schedules.wednesday_open
                      ? `${schedules.wednesday_open_time} - ${schedules.wednesday_close_time}`
                      : "Tutup"
                  }}
                </p>
              </li>
              <li class="tw-p-3 tw-border-b-2 tw-border-b-gray-100">
                <p class="tw-opacity-60 tw-mb-2">Kamis</p>
                <p
                  class="inter-semibold"
                  :class="
                    schedules.thursday_open
                      ? 'tw-text-black'
                      : 'tw-text-red-600 inter-bold'
                  "
                >
                  {{
                    schedules.thursday_open
                      ? `${schedules.thursday_open_time} - ${schedules.thursday_close_time}`
                      : "Tutup"
                  }}
                </p>
              </li>
              <li class="tw-p-3 tw-border-b-2 tw-border-b-gray-100">
                <p class="tw-opacity-60 tw-mb-2">Jumat</p>
                <p
                  class="inter-semibold"
                  :class="
                    schedules.friday_open
                      ? 'tw-text-black'
                      : 'tw-text-red-600 inter-bold'
                  "
                >
                  {{
                    schedules.friday_open
                      ? `${schedules.friday_open_time} - ${schedules.friday_close_time}`
                      : "Tutup"
                  }}
                </p>
              </li>
              <li class="tw-p-3 tw-border-b-2 tw-border-b-gray-100">
                <p class="tw-opacity-60 tw-mb-2">Sabtu</p>
                <p
                  class="inter-semibold"
                  :class="
                    schedules.saturday_open
                      ? 'tw-text-black'
                      : 'tw-text-red-600 inter-bold'
                  "
                >
                  {{
                    schedules.saturday_open
                      ? `${schedules.saturday_open_time} - ${schedules.saturday_close_time}`
                      : "Tutup"
                  }}
                </p>
              </li>
              <li class="tw-col-span-3 tw-p-3">
                <p class="tw-opacity-60 tw-mb-2">Minggu</p>
                <p
                  class="inter-semibold"
                  :class="
                    schedules.sunday_open
                      ? 'tw-text-black'
                      : 'tw-text-red-600 inter-bold'
                  "
                >
                  {{
                    schedules.sunday_open
                      ? `${schedules.sunday_open_time} - ${schedules.sunday_close_time}`
                      : "Tutup"
                  }}
                </p>
              </li>
            </ul>
          </aside>
        </section>
      </div>

      <div
        style="height: max(100%, 500px)"
        class="col-12 lg:col-6 tw-py-5 tw-px-4"
      >
        <GMapMap
          :center="center"
          :zoom="10"
          map-type-id="terrain"
          style="width: 100%; height: 500px"
        >
          <GMapCluster>
            <GMapMarker
              :key="index"
              v-for="(m, index) in markers"
              :position="m.position"
              :draggable="true"
            />
          </GMapCluster>
        </GMapMap>
      </div>
    </div>
  </div>

  <hr class="tw-bg-gray-200 tw-m-5" />

  <BankAccountList :salon-name="salon.name" />

  <BannerList :salon-id="route.params.id || salonId" />
</template>

<script setup>
import { useSalonForm } from "@/composables/salon";
import { onBeforeMount, ref } from "vue";
import { useRoute } from "vue-router";
import { useRouter } from "vue-router";
import BankAccountList from "./bank-accounts/BankAccountList";
import { useAuthRole } from "@/composables/auth";
import BannerList from "./banners/BannerList";

const route = useRoute();
const router = useRouter();
const { salonId } = useAuthRole();
const { isAdminPriti, isAdminSalon } = useAuthRole();
const showDenyModal = ref(false);
const showApproveModal = ref(false);
const reason = ref("");
const {
  salon,
  markers,
  center,
  schedules,
  getDetailSalon,
  getOperationalSchedules,
  approve,
  deny,
  activeInactiveSalon,
  loading,
} = useSalonForm();

let activeValue = ref();

const approveSalon = async () => {
  await approve(route.params.id);
  showApproveModal.value = false;
};

const denySalon = async () => {
  if (!showDenyModal.value) {
    showDenyModal.value = true;
    reason.value = "";
    return;
  }

  if (reason.value === "") reason.value = " ";

  var resp = await deny(route.params.id || salonId.value, reason.value);
  showDenyModal.value = false;
  reason.value = "";

  if (resp.status === 200) {
    salon.value.status = 2;
  }
};

onBeforeMount(async () => {
  await getDetailSalon(route.params.id || salonId.value);
  await getOperationalSchedules(route.params.id || salonId.value);
  activeValue = salon.value.isActive ? "Aktif" : "Tidak Aktif";
});
</script>

<style scoped lang="scss">
:deep(.p-buttonset .p-button:first-of-type) {
  padding-top: 6px;
  padding-bottom: 6px;
}
:deep(.p-buttonset .p-button:last-of-type) {
  padding-top: 6px;
  padding-bottom: 6px;
}
:deep(.p-selectbutton .p-button.p-highlight:not(:last-child)) {
  background-color: rgba(58, 160, 27, 1);
  border-color: rgba(58, 160, 27, 1);
  outline: 0;
  box-shadow: none;
}
:deep(.p-selectbutton .p-button.p-highlight) {
  background-color: rgba(207, 0, 0, 1);
  border-color: rgba(207, 0, 0, 1);
  outline: 0;
  box-shadow: none;
}
:deep(.p-selectbutton .p-button) {
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
}
</style>
